var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon
      }
    },
    [
      _c("v-tour", {
        attrs: {
          name: "RegistrationTour",
          steps: _vm.tourSteps,
          options: _vm.tourOptions
        }
      }),
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { color: "cardPrimary", title: "Profile" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        id: "v-step-0",
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.typeReload,
                                        props: props,
                                        "drop-down": "",
                                        label: "User Type",
                                        mandatory: "",
                                        name: "type",
                                        sortable: ""
                                      },
                                      model: {
                                        value: _vm.formDataF["type"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formDataF, "type", $$v)
                                        },
                                        expression: "formDataF['type']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.isOfficerAccount
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Institution",
                                            name: "institution"
                                          },
                                          model: {
                                            value: _vm.formDataF["institution"],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF,
                                                "institution",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['institution']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "First Name",
                                        name: "profile.firstName"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"]["firstName"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "firstName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['firstName']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Last Name",
                                        name: "profile.lastName"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"]["lastName"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "lastName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['lastName']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Date of Birth",
                                        name: "profile.birthDate"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"]["birthDate"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "birthDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['birthDate']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.nationalityReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "ctyDsc",
                                        "item-value": "ctyCod",
                                        label: "Nationality",
                                        mandatory: "",
                                        name: "profile.nationality",
                                        "show-item-avatar": "",
                                        sortable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"][
                                            "nationality"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "nationality",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['nationality']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.genderReload,
                                        props: props,
                                        "drop-down": "",
                                        label: "Gender",
                                        mandatory: "",
                                        name: "profile.gender",
                                        sortable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"]["gender"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "gender",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['gender']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { "with-border": "", title: "Identity" } },
                          [
                            _c(
                              "v-row",
                              [
                                _vm.isOfficerAccount
                                  ? _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "6" } },
                                      [
                                        _c("asyent-form-text", {
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            label: "Badge No",
                                            name: "profile.badgeNO"
                                          },
                                          model: {
                                            value:
                                              _vm.formDataF["profile"][
                                                "badgeNO"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["profile"],
                                                "badgeNO",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['profile']['badgeNO']"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.identityTypeReload,
                                        props: props,
                                        "drop-down": "",
                                        label: "Identity Type",
                                        mandatory: "",
                                        name: "profile.identityType",
                                        sortable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"][
                                            "identityType"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "identityType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['identityType']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-select", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        "items-reload": _vm.nationalityReload,
                                        props: props,
                                        "drop-down": "",
                                        "item-text": "ctyDsc",
                                        "item-value": "ctyCod",
                                        label: "Identity Nationality",
                                        mandatory: "",
                                        name: "profile.identityCountry",
                                        "show-item-avatar": "",
                                        sortable: ""
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"][
                                            "identityCountry"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "identityCountry",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['identityCountry']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Identity No#",
                                        name: "profile.identityNo"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"][
                                            "identityNo"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "identityNo",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['identityNo']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Date of Issuance",
                                        name: "profile.identityDate"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"][
                                            "identityDate"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "identityDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['identityDate']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-date", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Date of Expiry",
                                        name: "profile.identityExpiryDate"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["profile"][
                                            "identityExpiryDate"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["profile"],
                                            "identityExpiryDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['profile']['identityExpiryDate']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    !_vm.isOfficerAccount && !_vm.isAgentAccount
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: { title: "Traders" },
                                scopedSlots: _vm._u(
                                  [
                                    !_vm.isDisabled("traders") &&
                                    !props.disabled &&
                                    !props.readonly
                                      ? {
                                          key: "toolbar-actions",
                                          fn: function() {
                                            return [
                                              _c("asyent-button", {
                                                attrs: {
                                                  id: "v-step-trader",
                                                  "button-icon": "mdi-plus",
                                                  "button-tooltip":
                                                    "Add Trader",
                                                  small: "",
                                                  text: ""
                                                },
                                                on: {
                                                  confirmed: function($event) {
                                                    return _vm.formDataF[
                                                      "traders"
                                                    ].push({})
                                                  }
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      : null
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "* Mandatory to link your account with a trader (or traders)"
                                      )
                                    ]),
                                    _vm._l(_vm.formDataF["traders"], function(
                                      item,
                                      index
                                    ) {
                                      return [
                                        _c("asyent-form-challenge", {
                                          key: index,
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            "challenge-type": "trader",
                                            label: "Trader No",
                                            name: "traders"
                                          },
                                          on: {
                                            validated: function($event) {
                                              _vm.formDataF["traders"][
                                                index
                                              ] = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              !_vm.isDisabled("traders") &&
                                              !props.disabled &&
                                              !props.readonly
                                                ? {
                                                    key: "append-outer",
                                                    fn: function() {
                                                      return [
                                                        true
                                                          ? _c(
                                                              "asyent-button",
                                                              {
                                                                attrs: {
                                                                  disabled: false,
                                                                  "button-icon":
                                                                    "mdi-minus",
                                                                  "button-tooltip":
                                                                    "Delete",
                                                                  icon: "",
                                                                  "requires-confirmation":
                                                                    "",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  confirmed: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.formDataF[
                                                                      "traders"
                                                                    ].splice(
                                                                      index,
                                                                      1
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                : null
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.formDataF["traders"][index][
                                                "value"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["traders"][index],
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['traders'][index]['value']"
                                          }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isOfficerAccount && !_vm.isTraderAccount
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: { title: "Agents" },
                                scopedSlots: _vm._u(
                                  [
                                    !_vm.isDisabled("agents") &&
                                    !props.disabled &&
                                    !props.readonly
                                      ? {
                                          key: "toolbar-actions",
                                          fn: function() {
                                            return [
                                              _c("asyent-button", {
                                                attrs: {
                                                  id: "v-step-agent",
                                                  "button-icon": "mdi-plus",
                                                  "button-tooltip": "Add Agent",
                                                  small: "",
                                                  text: ""
                                                },
                                                on: {
                                                  confirmed: function($event) {
                                                    return _vm.formDataF[
                                                      "agents"
                                                    ].push({})
                                                  }
                                                }
                                              })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      : null
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "6" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "* Mandatory to link your account with an agent (or agents)"
                                      )
                                    ]),
                                    _vm._l(_vm.formDataF["agents"], function(
                                      item,
                                      index
                                    ) {
                                      return [
                                        _c("asyent-form-challenge", {
                                          key: index,
                                          attrs: {
                                            "form-errors": _vm.formErrors,
                                            "form-rules": _vm.formFieldsRules,
                                            "is-disabled-func": _vm.isDisabled,
                                            props: props,
                                            "challenge-type": "agent",
                                            label: "Agent No",
                                            name: "agents"
                                          },
                                          on: {
                                            validated: function($event) {
                                              _vm.formDataF["agents"][
                                                index
                                              ] = $event
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              !_vm.isDisabled("agents") &&
                                              !props.disabled &&
                                              !props.readonly
                                                ? {
                                                    key: "append-outer",
                                                    fn: function() {
                                                      return [
                                                        true
                                                          ? _c(
                                                              "asyent-button",
                                                              {
                                                                attrs: {
                                                                  disabled: false,
                                                                  "button-icon":
                                                                    "mdi-minus",
                                                                  "button-tooltip":
                                                                    "Delete",
                                                                  icon: "",
                                                                  "requires-confirmation":
                                                                    "",
                                                                  small: ""
                                                                },
                                                                on: {
                                                                  confirmed: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.formDataF[
                                                                      "agents"
                                                                    ].splice(
                                                                      index,
                                                                      1
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                : null
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.formDataF["agents"][index][
                                                "value"
                                              ],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formDataF["agents"][index],
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formDataF['agents'][index]['value']"
                                          }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "User Login" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        id: "v-step-username",
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Login Identifier",
                                        name: "loginIdentifier"
                                      },
                                      model: {
                                        value: _vm.formDataF["loginIdentifier"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF,
                                            "loginIdentifier",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['loginIdentifier']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-challenge", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        "challenge-type": "email",
                                        label: "Email Address",
                                        name: "emailAddress"
                                      },
                                      on: {
                                        validated: function($event) {
                                          _vm.formDataF["emailAddress"] = $event
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["emailAddress"][
                                            "value"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["emailAddress"],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['emailAddress']['value']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "6" } },
                                  [
                                    _c("asyent-form-challenge", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        "challenge-type": "mobile",
                                        label: "Primary Mobile No",
                                        name: "primaryMobile"
                                      },
                                      on: {
                                        validated: function($event) {
                                          _vm.formDataF[
                                            "primaryMobile"
                                          ] = $event
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF["primaryMobile"][
                                            "value"
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF["primaryMobile"],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['primaryMobile']['value']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Password",
                                        name:
                                          "credentialPasswordModel.credentialValue",
                                        type: "password"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF[
                                            "credentialPasswordModel"
                                          ]["credentialValue"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF[
                                              "credentialPasswordModel"
                                            ],
                                            "credentialValue",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['credentialPasswordModel']['credentialValue']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Password Confirmation",
                                        name:
                                          "credentialPasswordModel.credentialChallenge",
                                        type: "password"
                                      },
                                      model: {
                                        value:
                                          _vm.formDataF[
                                            "credentialPasswordModel"
                                          ]["credentialChallenge"],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formDataF[
                                              "credentialPasswordModel"
                                            ],
                                            "credentialChallenge",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formDataF['credentialPasswordModel']['credentialChallenge']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", lg: "6" } },
                      [
                        _c("AccountsAddresses", {
                          attrs: {
                            "is-disabled-func": _vm.isDisabled,
                            "item-headings": [
                              "Type",
                              "Address Name",
                              "Line1",
                              "Line2",
                              "City",
                              "State, Province or Region",
                              "Postal Code",
                              "Country"
                            ],
                            "item-values": [
                              "addressType",
                              "addressName",
                              "addressLine1",
                              "addressLine2",
                              "city",
                              "stateOrProvinceOrRegion",
                              "zipOrPostalCode",
                              "country"
                            ],
                            readonly: _vm.isReadonly,
                            name: "addresses",
                            title: "Addresses"
                          },
                          model: {
                            value: _vm.formDataF["addresses"],
                            callback: function($$v) {
                              _vm.$set(_vm.formDataF, "addresses", $$v)
                            },
                            expression: "formDataF['addresses']"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.formValid,
          callback: function($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }